<template>
  <div class="order-detail">
    <baseContainer back page="事项详情">
      <div class="order-info">
        <el-button class="iconfont icon-bianji" type="primary" @click="isEdit = true" v-show="!isEdit && orderDetail.status === 'completed'">编辑</el-button>
        <div class="edit-btn">
          <el-button type="primary" class="iconfont icon-dcicon_save1" @click="saveEdit" v-show="isEdit">保存</el-button>
          <el-button class="iconfont icon-dcx" @click="cancelEdit" v-show="isEdit">取消</el-button>
        </div>
        <div class="split-title"><span>基本信息</span></div>
        <div class="order-user-info">
          <div class="info-left-box">
            <div class="info-detail">
              <div>
                <span class="info-detail-title">{{ orderDetail.title }}</span>
                <span class="info-detail-status" :class="{ 'status-complete': orderDetail.status === 'completed', 'status-fail': orderDetail.status === 'handledFailure' }">{{
                  orderDetail.statusName
                }}</span>
              </div>
              <div>
                <i class="iconfont"></i><span>{{ orderDetail.areaLocation }}</span>
              </div>
              <div class="info-split-detail">
                <span><strong>事项编号：</strong>{{ orderDetail.orderId }}</span>
                <span><strong>上报部门：</strong>{{ orderDetail.orderCreateDept }}</span>
                <span><strong>经办部门：</strong>{{ orderDetail.orderBelongDept }}</span>
              </div>
              <div class="info-split-detail">
                <span><strong>上报人：</strong>{{ orderDetail.createName }}</span>
                <span><strong>电话号码： </strong>{{ orderDetail.reporterMobile }}</span>
                <span><strong>上报时间： </strong>{{ formatDate(orderDetail.reportTime) }}</span>
              </div>
              <div class="info-split-detail">
                <span><strong>问题分类：</strong>{{ orderDetail.matterClassficationName }}</span>
                <span><strong>事项类型： </strong>{{ orderDetail.orderClassficationName }}</span>
                <span><strong>问题性质：</strong>{{ orderDetail.matterPropertyName }}</span>
              </div>
              <div class="info-split-detail">
                <span><strong>办理时长(h)：</strong>{{ orderDetail.totalHours }}</span>
                <span><strong>积分奖励：</strong>{{ orderDetail.points }} <i class="iconfont icon-bianji" @click="setScore" v-show="isEdit"></i></span>
              </div>
              <div class="info-split-detail">
                <span
                  ><strong>是否可见：</strong><i v-show="!isEdit">{{ orderDetail.visible == '1' ? '是' : '否' }}</i
                  ><el-switch v-model="orderDetail.visible" :active-value="1" :inactive-value="0" v-show="isEdit" @change="changeVisible"></el-switch
                ></span>
                <span
                  ><strong>是否最佳实践：</strong><i v-show="!isEdit">{{ orderDetail.isPractice == '1' ? '是' : '否' }}</i
                  ><el-switch v-model="orderDetail.isPractice" :active-value="1" :inactive-value="0" v-show="isEdit" @change="changePractice"></el-switch
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </baseContainer>
    <div class="order-tabs">
      <el-tabs v-model="activeName" @tab-click="handleClick" type="card">
        <el-tab-pane label="事项信息" name="first">
          <div class="tab-detail">
            <div class="border">
              <strong>问题描述</strong>
              <p>
                {{ orderDetail.des }}
              </p>
            </div>
            <div class="border">
              <strong>建议及整改期望</strong>
              <p>
                {{ orderDetail.advice }}
              </p>
            </div>
            <div class="border">
              <strong>问题发生区域</strong>
              <p>{{ orderDetail.areaLocation }}</p>
            </div>
            <div class="border" v-if="orderDetail.pictureUrl">
              <strong>图片</strong>
              <div class="img-box">
                <vviewer :images="beforeImg" />
                <!-- <img :src="orderDetail.pictureUrl" :onerror="defaultImg" /> -->
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="流程记录" name="second">
          <div class="tab-block">
            <el-timeline>
              <el-timeline-item v-for="(item, index) in processing" :key="index" :timestamp="formatDate(item.updateTime)" placement="top">
                <el-card>
                  <div class="card-info">
                    <div class="info-left">
                      <el-avatar :size="40" :src="item.operateUserAvatar"></el-avatar>
                      <div class="user-info">
                        <strong>{{ item.operateUserName }}</strong>
                        <p>{{ item.department }}</p>
                      </div>
                    </div>
                    <div class="handle-info">
                      {{ item.operateTypeName }} {{ item.assignedUserName }} <span v-if="item.assignedUserMobile">({{ item.assignedUserMobile }})</span><br />
                      {{ item.operateDesc }}
                    </div>
                  </div>
                  <span
                    class="status"
                    :class="{
                      'status-fail': item.operateTypeName === '暂不受理',
                      'status-back': item.operateTypeName === '回退给' || item.operateTypeName === '撤回',
                      'status-accpeted': item.operateTypeName === '处理完成' || item.operateTypeName === '验收完成',
                      'status-wait':
                        item.operateTypeName === '交办给' || item.operateTypeName === '上报给' || item.operateTypeName === '保存草稿' || item.operateTypeName === '创建'
                    }"
                    >{{ item.operateTypeName.replace(/给/g, '') }}</span
                  >
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </div>
        </el-tab-pane>
        <el-tab-pane label="处理记录" name="third" v-if="feReceipt">
          <div class="tab-detail">
            <div class="border">
              <strong>处理信息</strong>
              <p>
                <span
                  >处理人：{{ feReceipt.createName }}<i v-if="feReceipt.createMobile">({{ feReceipt.createMobile }})</i></span
                >
              </p>
              <p>
                <span>处理时间：{{ formatDate(feReceipt.createTime) }}</span>
              </p>
            </div>
            <div class="border">
              <strong>处理描述</strong>
              <p>{{ feReceipt.receiptDesc }}</p>
            </div>
            <div class="border" v-if="feReceipt.receiptPictureUrl">
              <strong>图片</strong>
              <div class="img-box">
                <vviewer :images="afterImg" />
                <!-- <img :src="feReceipt.receiptPictureUrl" :onerror="defaultImg" /> -->
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- <baseDialog
      title="设置评价"
      formName="setForm"
      :dialogVisible="dialogSetFormVisible"
      visibleName="dialogSetFormVisible"
      @cancel="cancel($event)"
      @submit="submitScoreForm($event)"
    >
      <el-form :model="scoreForm" :rules="scoreRules" ref="scoreForm">
        <el-form-item label="最佳实践" :label-width="formLabelWidth">
          <el-switch v-model="scoreForm.isBest"></el-switch>
        </el-form-item>
        <el-form-item label="积分奖励" :label-width="formLabelWidth" prop="score">
          <el-input v-model="scoreForm.score" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="积分备注" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="scoreForm.desc"></el-input>
        </el-form-item>
      </el-form>
    </baseDialog>
    <baseDialog title="设置可见" formName="setShow" :dialogVisible="dialogSetShowVisible" visibleName="dialogSetShowVisible" @cancel="cancel($event)" @submit="submitShowForm">
      <el-form>
        <span style="margin-left: 10px">此事项案例及解决办法，是否同步到企业案例库，供员工查看学习</span>
        <el-form-item label="是否公开可见：" label-width="120px">
          <el-switch v-model="isShow"></el-switch>
        </el-form-item>
      </el-form>
    </baseDialog> -->
    <userDialog ref="dialog" @changeScore="changeScore" />
  </div>
</template>

<script>
import baseContainer from '@/components/container/base-container';
// import baseDialog from '@/components/dialog/base-dialog';
import userDialog from '@/components/user-dialog/user-dialog';
import vviewer from '@/components/viewer/viewer';
import { table } from './mixin';
import '@/common/style/detail.scss';
export default {
  name: 'completedDetail',
  mixins: [table],
  props: ['id'],
  data() {
    return {
      isEdit: false,
      isBest: false,
      isShow: false,
      orderDetail: {},
      feReceipt: {},
      processing: [],
      searchText: '',
      orderType: '',
      problemType: '',
      status: '',
      formLabelWidth: '80px',
      scoreForm: {
        score: '',
        desc: '',
        isBest: true
      },
      scoreRules: {
        score: [{ required: true, message: '请输入积分', trigger: 'blur' }]
      },
      dialogSetFormVisible: false,
      dialogSetShowVisible: false,
      // isShow: true,
      activeName: 'first',
      detailInfo: {},
      beforeImg: [],
      afterImg: []
    };
  },
  activated() {
    // this.loadData();
    this.beforeImg = [];
    this.afterImg = [];
    this.getDetail();
    this.activeName = 'first';
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.$confirm('是否放弃编辑?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
          this.isEdit = false;
          next();
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
          next(false);
        });
    } else {
      this.isEdit = false;
      next();
    }
  },
  computed: {
    defaultImg() {
      return `this.src="${require('@/assets/img/default.png')}"`;
    }
  },
  methods: {
    async getDetail() {
      const { data } = await this.$apis.workOrder.getDetail(this.id);
      this.orderDetail = data;
      this.beforeImg.push(data.pictureUrl);
      this.processing = data.records;
      this.feReceipt = data.feReceipt;
      this.oldPoits = data.points;
      if (this.feReceipt && this.feReceipt.receiptPictureUrl) {
        this.afterImg.push(this.feReceipt.receiptPictureUrl);
      }
      this.$refs.dialog.init(data.points);
    },
    cancel(formVisible) {
      this[formVisible] = false;
    },
    //取消编辑
    cancelEdit() {
      this.isEdit = false;
      this.getDetail();
    },
    //设置积分
    setScore() {
      this.$refs.dialog.change();
    },
    changeScore(params) {
      this.points = params.score;
      this.orderDetail.points = this.oldPoits + parseInt(params.score);
      this.remark = params.desc;
      // console.log(params);
    },
    setBest(formVisible) {
      this[formVisible] = true;
    },
    submitShowForm() {},
    handleClick(tab, event) {
      console.log(tab, event);
    },
    async saveEdit() {
      let params = {
        orderId: this.orderDetail.orderId,
        remark: this.remark,
        points: this.points,
        isPractice: this.changePracticeVal,
        visible: this.changeVisibleVal
      };
      this.isEdit = false;
      const data = await this.$apis.workOrder.edit(params);
      if (data.resp_code === 0) {
        this.getDetail();
      }
    },
    changeVisible(val) {
      this.changeVisibleVal = val;
    },
    changePractice(val) {
      this.changePracticeVal = val;
    }
  },
  components: {
    baseContainer,
    // baseDialog,
    userDialog,
    vviewer
  }
};
</script>
<style lang="scss" scoped>
.tab-detail {
  padding: 0;
  p {
    white-space: pre-wrap;
  }
}
.order-info {
  padding-bottom: 0;
}
.split-title {
  // margin-top: -10px;
}
.order-user-info {
  margin-top: 30px;
  padding-left: 20px;
}
.info-detail {
  .info-detail-title {
    font-size: 18px;
  }
  .info-split-detail {
    margin-top: 20px;
    display: flex;
    span {
      font-size: 14px;
      display: block;
      width: 33%;
      color: #121212;
    }
    strong {
      color: #5a5a5a;
      font-weight: normal;
    }
  }
}
.order-tabs {
  padding: 0;
  .border {
    padding-left: 20px;
    padding-top: 10px;
  }
}
.tab-block {
  padding: 10px 20px;
}
</style>